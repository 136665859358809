export const reducer = (state, action) => {
  switch (action.type) {
    case 'update_clearState':
      return {
        initialState,
      };

    case 'update_clearDates':
      return {
        ...state,
        checkInDate: '',
        checkOutDate: '',
      };

    case 'update_events':
      return {
        ...state,
        events: action.value,
      };

    case 'update_email':
      return {
        ...state,
        emailAddress: action.value,
      };

    case 'update_firstName':
      return {
        ...state,
        firstName: action.value,
      };

    case 'update_lastName':
      return {
        ...state,
        lastName: action.value,
      };

    case 'update_addressLine1':
      return {
        ...state,
        addressLine1: action.value,
      };

    case 'update_addressLine2':
      return {
        ...state,
        addressLine2: action.value,
      };

    case 'update_city':
      return {
        ...state,
        city: action.value,
      };

    case 'update_postcode':
      return {
        ...state,
        postcode: action.value,
      };

    case 'update_country':
      return {
        ...state,
        country: action.value,
      };

    case 'update_telephone':
      return {
        ...state,
        telephone: action.value,
      };

    case 'update_notes':
      return {
        ...state,
        notes: action.value,
      };

    case 'update_bringDog':
      return {
        ...state,
        bringingDog: action.value,
      };

    case 'update_bedrooms':
      return {
        ...state,
        rooms: action.value,
      };

    case 'update_thisBedrooms':
      const allThisBedrooms = state.allRooms;
      let currentStoredRooms = state.storedRooms;

      allThisBedrooms.map((room) => {
        if (room.roomId === action.roomId) {
          room.numberOfGuests = action.value;

          if (state.selectedRooms > action.value) {
            currentStoredRooms
              .filter((room) => room.roomId === action.roomId)[0]
              ?.selectedRooms.splice(-1);
          }
        }

        return room;
      });

      return {
        ...state,
        allRooms: allThisBedrooms,
        selectedRooms: action.value,
        storedRooms: currentStoredRooms,
      };

    case 'update_guests':
      return {
        ...state,
        guests: action.value,
      };

    case 'update_checkInDate':
      return {
        ...state,
        checkInDate: action.value,
      };

    case 'update_checkOutDate':
      return {
        ...state,
        checkOutDate: action.value,
      };

    case 'update_membersCode':
      return {
        ...state,
        membersCode: action.value,
      };

    case 'update_expiry':
      return {
        ...state,
        expiry: action.value,
      };

    case 'update_errors':
      return {
        ...state,
        errors: action.value,
      };

    case 'update_tandcs':
      return {
        ...state,
        agreedToTandCs: action.value,
      };

    case 'update_newsletter':
      return {
        ...state,
        hearLatestNews: action.value,
      };

    case 'update__allRooms':
      let updatedAllRooms = action.value;
      if (updatedAllRooms !== null) {
        updatedAllRooms.forEach((room) => {
          room.numberOfGuests = 1;
          room.rooms = [];
        });
      }

      return {
        ...state,
        allRooms: action.value,
      };

    case 'update_transactionId':
      return {
        ...state,
        transactionId: action.value,
      };

    case 'update_doggoPricing':
      return {
        ...state,
        doggoPricing: action.value,
      };

    case 'update_stepFourContent':
      return {
        ...state,
        stepFour: action.value,
      };

    case 'update_selectedRooms':
      const roomId = action.value.roomId; // ID from mews
      const allRooms = state.allRooms;

      allRooms.map((room) => {
        if (room.roomId === action.value.roomId) {
          let thisRooms = room.rooms;

          const {
            roomKey,
            content,
            guests,
            dogs,
            rate: { rateId, rateName, averageAmount, totalAmount },
          } = action.value;

          const foundRoomKey = room.rooms.some((r) => r.key === roomKey);

          if (!foundRoomKey) {
            let newEntry = {
              key: roomKey,
              guests: guests,
              dogs: dogs,
              roomId: roomId,
              rate: action.value.rate,
              content: content,
            };

            thisRooms.push(newEntry);
          } else {
            for (let i = 0; i < thisRooms.length; i++) {
              const room = thisRooms[i];
              if (room.key === roomKey) {
                thisRooms[i].rate.rateId = rateId;
                thisRooms[i].guests = guests;
                thisRooms[i].dogs = dogs;
                thisRooms[i].rate.rateName = rateName;
                thisRooms[i].rate.averageAmount = averageAmount;
                thisRooms[i].rate.totalAmount = totalAmount;
                thisRooms[i].content = content;
              }
            }
          }

          room.rooms = thisRooms;
        }

        return room;
      });

      return {
        ...state,
        allRooms: allRooms,
      };

    case 'update_rootSelectedRooms':
      if (action.value != null) {
        let currentStoredRooms = state.storedRooms;
        let newRoom = action.value;
        let newRoomId = newRoom.roomId;
        let newRoomIdExists = currentStoredRooms.some(
          (room) => room.roomId === newRoomId
        );

        if (!newRoomIdExists) {
          let newRoomObject = {
            roomId: newRoomId,
            selectedRooms: [newRoom],
          };
          currentStoredRooms.push(newRoomObject);
        } else {
          let roomObjectToUpdate = currentStoredRooms.filter(
            (room) => room.roomId === newRoomId
          )[0];

          const foundRoomKey = roomObjectToUpdate.selectedRooms.some(
            (r) => r.roomKey === newRoom.roomKey
          );

          if (!foundRoomKey) {
            roomObjectToUpdate.selectedRooms.push(newRoom);
          } else {
            for (let i = 0; i < roomObjectToUpdate.selectedRooms.length; i++) {
              if (
                roomObjectToUpdate.selectedRooms[i].roomKey === newRoom.roomKey
              ) {
                roomObjectToUpdate.selectedRooms[i] = newRoom;
              }
            }
          }
        }

        return {
          ...state,
          storedRooms: currentStoredRooms,
        };
      } else {
        return {
          ...state,
          storedRooms: [],
        };
      }

    case 'update_deselectRoom':
      return {
        ...state,
        storedRooms: state.storedRooms.filter(
          (room) => room.roomId !== action.value
        ),
      };

    case 'remove_selectedRoom':
      const roomKeyToBeUpdated = action.value.roomKey;
      const roomIdToBeUpdated = action.value.roomId;

      const selectedRooms = state.storedRooms;

      let roomToBeUpdated = selectedRooms.filter(
        (room) => room.roomId === roomIdToBeUpdated
      )[0];

      roomToBeUpdated.selectedRooms = roomToBeUpdated.selectedRooms.splice(
        roomKeyToBeUpdated,
        1
      );

      return {
        ...state
      };

    case 'update_disabledDates':
      return {
        ...state,
        disabledDates: action.value
      }

    default:
      return state;
  }
};

export const initialState = {
  doggoPricing: null,

  disabledDates: [],

  checkInDate: '',
  checkOutDate: '',
  selectedRooms: 0,

  rooms: 1,
  guests: 2,

  storedRooms: [],
  allRooms: [],
  roomDetails: [],

  membersCode: '',

  firstName: '',
  lastName: '',
  emailAddress: '',
  telephone: '',

  addressLine1: '',
  addressLine2: '',
  city: '',
  postcode: '',
  country: 'GB',

  notes: '',
  bringingDog: false,
  expiry: '',
  agreedToTandCs: false,
  hearLatestNews: false,
  events: [],
  errors: '',
  transactionId: '',
  stepFour: {},
};
