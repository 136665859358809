import { useState, useContext, useEffect } from 'react';
import slugify from 'react-slugify';
import { BookingContext } from '../../contexts/Booking';

export default function Checkbox(props) {
  const [localState, updateLocalState] = useState({ checked: false });
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(BookingContext);

  useEffect(() => {
    if (props.isChecked) {
      updateLocalState({
        checked: true,
      });
    }
  }, [props.isChecked]);

  function onCheckboxChange(e) {
    const el = e.target.name;
    updateLocalState({
      checked: !localState.checked,
    });

    switch (el) {
      case 'bringMyDog':
        dispatch({ type: 'update_bringDog', value: !localState.checked });
        break;

      case 'tandcs':
        dispatch({ type: 'update_tandcs', value: !localState.checked });
        break;

      case 'newsletter':
        dispatch({ type: 'update_newsletter', value: !localState.checked });
        break;

      default:
        break;
    }
  }

  return (
    <div className={`checkbox__wrap ${props.className ? props.className : ''} ${props.hasError ? 'checkbox__error' : ''}`}>
      <label
        className={`checkbox ${props.ExtraClass ? props.ExtraClass : ''}`}
        htmlFor={
          props.For
            ? props.For
            : (props.Label
            ? slugify(props.Label)
            : slugify(props.children))
        }
      >
        <input
          type="checkbox"
          id={
            props.For
              ? props.For
              : (props.Label
              ? slugify(props.Label)
              : slugify(props.children))
          }
          name={props.For ? props.For : slugify(props.Label)}
          className="checkbox__input"
          onChange={(e) => onCheckboxChange(e)}
          checked={localState.checked}
        />
        <div className="checkbox__check"></div>
        <div className="checkbox__label">
          {props.Label}
          {props.children}
        </div>
      </label>

      {props.HasError &&
          <div className="field__wrap__validation-message field__wrap__validation-message--failed">
              {props.ErrorMessage}
          </div>
      }
    </div>
  );
}
