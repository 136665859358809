import { useState } from 'react';

import { Button, Heading, Rte } from '../../components/index';

export default function Modal({ trigger }) {
  const [isModalShowing, updateisModalShowing] = useState(false);

  const toggleModal = () => {
    updateisModalShowing(!isModalShowing);
  };

  return (
    <>
      <div onClick={() => toggleModal()} className="modal__trigger">
        {trigger}
      </div>

      <div className={`modal ${isModalShowing ? 'modal--show' : ''}`}>
        <div onClick={() => toggleModal()} className="modal__bg"></div>

        <div className="modal__body">
          <div className="modal__close" onClick={() => toggleModal()}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.30618 15.7735L8.00289 9.07692L14.6996 15.7735C14.8431 15.9144 15.0363 15.9929 15.2374 15.992C15.4385 15.9911 15.631 15.9108 15.7732 15.7686C15.9153 15.6265 15.9956 15.4339 15.9965 15.2328C15.9975 15.0318 15.9189 14.8385 15.7781 14.6951L9.08136 7.99847L15.7781 1.30187C15.9208 1.1582 16.0007 0.963701 16 0.761171C15.9993 0.558641 15.9183 0.364667 15.7746 0.22192C15.6309 0.0791736 15.4364 -0.000652032 15.2339 4.01198e-06C15.0313 0.000660056 14.8374 0.081744 14.6946 0.225418L7.9979 6.92201L1.30118 0.225418C1.23017 0.154737 1.14594 0.0987349 1.05328 0.0606082C0.96063 0.0224816 0.861374 0.00297744 0.761182 0.00320947C0.660991 0.0034415 0.561826 0.0234051 0.46935 0.0619605C0.376873 0.100516 0.292897 0.156908 0.222215 0.227917C0.151533 0.298926 0.0955291 0.383161 0.0574018 0.475814C0.0192745 0.568466 -0.000229987 0.66772 2.04616e-06 0.76791C0.000234079 0.8681 0.0201981 0.967263 0.0587541 1.05974C0.0973102 1.15221 0.153703 1.23619 0.224714 1.30687L6.92143 8.00346L0.224714 14.7001C0.153927 14.771 0.0978072 14.8551 0.0595625 14.9477C0.0213178 15.0404 0.00169751 15.1396 0.00182291 15.2398C-0.000867591 15.3403 0.0175814 15.4403 0.0559763 15.5333C0.0943711 15.6263 0.151857 15.7102 0.224714 15.7795C0.368623 15.9208 0.562256 16 0.763949 16C0.965642 16 1.15927 15.9208 1.30318 15.7795L1.30618 15.7735Z"
                fill="black"
              />
            </svg>
          </div>

          <Heading Size="md" MainHeading="Dog House rules" />

          <div className="modal__content">
            <Rte
              html={`<img src="https://i.imgur.com/9C1OwaT.png" alt="" /><p><strong>In order to provide a welcoming and comfortable environment for all of our guests, we have put together some rules that we ask all dog-owners to follow during their stay with us:</strong></p><br/>
              <ul>
                <li>Please note that all bookings with dogs incur an additional £25 cleaning fee</li>
                <li>Please <b>do not</b> leave your dog in your bedroom alone if you know your dog tends to bark. <em>A barking dog is very disruptive so please be considerate of others.</em></li>
                <li>Please only bring one dog per room, as our rooms do not have space for more than one.</li>
                <li>Please keep your dog on a lead at all times throughout the hotel and garden area.</li>
                <li>Please <b>do not</b> allow your dog in the Snug or Dining room areas. If you are dining with us, you will be invited to sit in our Bar area and you’ll be able to relax in our Sitting Room.</li>
                <li>Please ensure your dog <b>does not</b> go on any furniture, in bathtubs, basins or in the showers. <em>*If we notice your dog has been on any furniture in your room or the main part of the hotel, we will need to add £100 charge for additional cleaning.</em></li>
                <li>Please always pick up any waste and dispose of it in the appropriate bins.</li>
                <li>When coming back from the beach, please wash you dog (and your shoes) in the bath by the entrance.</li>
              </ul>`}
            />
          </div>

          <Button
            ExtraClass="button--wide"
            Label="Close"
            onClick={() => toggleModal()}
          />
        </div>
      </div>
    </>
  );
}
