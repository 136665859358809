import { useContext, useState } from 'react';
import { BookingContext } from '../../contexts/Booking';
import { BookingCheckbox } from '../../components/index';
import SecureFields from './SecureFields/SecureFields';

const isDev = process.env.REACT_APP_DEV === 'true';

export default function PaymentField(props) {
  const [state, dispatch] = useContext(BookingContext);
  const [tsandcsHasError, setTsandcsHasError] = useState(false);

  const secureFieldsConfig = {
    merchantID: !isDev ? '3000013748' : '1100016827',
    fields: {
      cardNumber: {
        placeholderElementId: 'card-number',
        inputType: 'tel',
      },
      cvv: {
        placeholderElementId: 'cvv-number',
        inputType: 'tel',
      },
    },
    options: {},
  };

  const handleTransactionId = (transactionBody) => {
    const expiryDate = transactionBody.body.expiry.split('/');
    const formattedExpiryDate = `20${expiryDate[1]}-${expiryDate[0]}`;

    transactionBody.body.expiry = formattedExpiryDate;

    props.transactionIdCallback(transactionBody);
  };

  const handleTsandCsError = () => !state.agreedToTandCs ? setTsandcsHasError(true) : setTsandcsHasError(false);
  const handleEmitValidation = () => props.validationCallback();
  const handleUpdateExpiry = (exp) => dispatch({type: 'update_expiry', value: exp})

  return (
    <>
      <SecureFields
        config={secureFieldsConfig}
        state={state}
        callback={handleTransactionId}
        TsandCsCallback={handleTsandCsError}
        validateCallBack={handleEmitValidation}
        expiryUpdateCallback={handleUpdateExpiry}
        isDev={isDev}
        expiry={state.expiry}
        validationPassed={props.validationPassed}
      >
        <BookingCheckbox
          className="space-me-out-horizontally-on-tablet"
          ExtraClass="checkbox--alt"
          Name="tandcs"
          For="tandcs"
          Field="tandcs"
          HasError={tsandcsHasError}
          ErrorMessage="Please accept our terms and conditions"
        >
          <p>I have read and agree to the</p>{' '}
          <a
            href={props.termsAndConditionsLink}
            target="_blank"
            rel="noreferrer"
            title="terms and conditions"
          >
            terms and conditions
          </a>
        </BookingCheckbox>
      </SecureFields>
    </>
  );
}
