import moment from 'moment';
import { useEffect, useState, useContext } from 'react';
import { BookingContext } from '../../contexts/Booking';

import {
  Container,
  Grid,
  Column,
  Heading,
  Link,
  PictureCard,
  EventsCalendarList,
  Rte,
  Loader,
} from '../../components/index';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import gtag, { install } from 'ga-gtag';
install('G-8964VWSZNC', { send_page_view: false });
install('UA-8220260-4', { send_page_view: false });

function submitToGA(total, reservationId, state, requestedRooms) {
  const orderItemTemplate = {
    id: '',
    name: '',
    category: 'Bedroom',
    quantity: '',
    price: '',
  };
  let orderItems = [];

  for (const room of requestedRooms) {
    ReactGA4.event({
      category: 'purchase',
      action: 'purchase',
      id: room.roomId,
      name: room.rate.rateName,
      price: room.rate.totalAmount,
      quantity: 1,
      currency: 'GBP',
    });

    ReactGA.plugin.execute('ec', 'addProduct', {
      id: room.roomId,
      name: room.rate.rateName,
      price: room.rate.totalAmount,
      quantity: 1,
      currency: 'GBP',
    });
    console.log(room);
  }

  let rooms = state.allRooms;

  rooms.forEach((r) => {
    let newRoomObject = { ...orderItemTemplate };

    newRoomObject.id = r.roomId;
    newRoomObject.name = r.content.roomName;
    newRoomObject.quantity = r.rooms.length;
    newRoomObject.price = r.price[0].averageAmount;

    orderItems.push(newRoomObject);
  });

  ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
    transaction_id: reservationId,
    affiliation: 'Gallivant Booking Engine',
    value: total,
    currency: 'GBP',
    tax: total * 0.2,
    shipping: 0,
    items: orderItems,
  });

  ReactGA.plugin.execute('ec', 'clear');

  gtag('event', 'purchase', {
    transaction_id: reservationId,
    affiliation: 'Gallivant Booking Engine',
    value: total,
    currency: 'GBP',
    tax: total * 0.2,
    shipping: 0,
    items: orderItems,
  });
}

function StepFour(props) {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(BookingContext);
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [content, setContent] = useState({});
  const [experiences, setExperiences] = useState({});
  const [events, setEvents] = useState({});
  const arrivingOn = moment(state.checkInDate);
  const leavingOn = moment(state.checkOutDate);
  const totalNights = leavingOn.diff(arrivingOn, 'days');
  let requestedRooms = [];

  state.allRooms.forEach((r) => {
    if (r.rooms.length) {
      requestedRooms.push(r.rooms);
    }
  });

  requestedRooms = requestedRooms.flat(1);

  useEffect(() => {
    dispatch({ type: 'update_clearDates' });

    const url = window.location.href;
    const urlParts = url.split('resId=');
    const resId = urlParts[1];

    let reducer = (acc, item) => {
      let total = acc + item.rate.averageAmount * totalNights;
      let returnPrice = 0;

      if (item.dogs === 1 && state.doggoPricing) {
        returnPrice = total + state.doggoPricing.price * totalNights;
      } else {
        returnPrice = total;
      }

      return returnPrice;
    };

    let totalPrice = requestedRooms.reduce(reducer, 0);
    let finalPrice = (Math.round(totalPrice * 100) / 100).toFixed(2);

    async function fetchData(reservationId) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ROOT}api/BookingStep/ConfirmReservation/${reservationId}`
        );
        const json = await response.json();
        if (json.content) {
          setContent(json.content);
          props.helmetData({
            title: json.content.seotitle,
            description: json.content.seoDescription,
          });
          setExperiences(json.content.experiences);
          setEvents(json.events);
          setIsLoaded(true);
          submitToGA(finalPrice, resId, state, requestedRooms);
        } else {
          history.push('/');
        }
      } catch (e) {
        console.error(e);
      }
    }
    fetchData(resId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const EventsDuringYourStay = () => {
    if (events.length) {
      return <EventsCalendarList content={events} />;
    } else {
      return <Rte>Sorry, there are no events during your stay.</Rte>;
    }
  };

  if (isLoaded) {
    return (
      <div className="Step StepFour animate__animated animate__fadeIn">
        <Container ExtraClass="container--narrow">
          <Grid Justify="space-between">
            <Column Width="60">
              <Grid ExtraClass="grid--spaced-bottom-lg">
                <Column Width="35">
                  <Heading
                    Size="lg"
                    PreHeading={content.pageTitle}
                    MainHeading={content.pageSubtitle}
                  />
                </Column>
              </Grid>

              <Grid ExtraClass="grid--spaced-bottom-sm">
                <Column Width="100">
                  <Heading
                    Size="mlg"
                    MainHeading={content.contentTitle}
                    MainText={content.contentDescription}
                  />

                  <Heading
                    Size="xs"
                    MainHeading={content.membershipContentDescription}
                  />

                  <div className="space-me-out-horizontally-on-tablet">
                    <Link
                      Type="button"
                      Url={content.tockLink}
                      Target="_blank"
                      Label={content.tockLinkLabel}
                      AdditionalText={content.tockLinkDescription}
                      ExtraClass="button--alt"
                    />
                  </div>
                </Column>
              </Grid>

              <Grid>
                <Column Width="100">
                  {experiences &&
                    experiences.map((card, i) => (
                      <PictureCard
                        Image={
                          process.env.REACT_APP_CMS_ROOT + card.experienceImage
                        }
                        Title={card.experienceTitle}
                        Content={card.experienceDescription}
                        Link={card.experienceLink}
                        key={i}
                      />
                    ))}
                </Column>
              </Grid>
            </Column>

            <Column Width="35">
              <EventsDuringYourStay />
            </Column>
          </Grid>
        </Container>
      </div>
    );
  } else {
    return <Loader />;
  }
}

export default StepFour;
