import React from 'react'
import { Heading } from '..';
import RoomsSidebarCard from './RoomSidebarCard';

export default function RoomsSidebar(props) {
    return (
      <div className="rooms-sidebar">
        <Heading
          Size="xs"
          ExtraClass="rooms-sidebar__heading"
          PreHeading={props.subTitle}
          MainHeading={props.title}
        />

        {props.Cards.map((card, i) => (
          <RoomsSidebarCard Data={card} key={i} />
        ))}
      </div>
    );
}
