import React from 'react';

import { Heading, Link } from '../index';

export default function PictureCard(props) {
  return (
    <div className="picture-card">
      <div className="picture-card__img">
        <img src={props.Image} alt={props.Image.alt} />
      </div>

      <div className="picture-card__content">
        <Heading Size="md" MainHeading={props.Title} MainText={props.Content} />
        <Link
          Url={props.Link}
          Label="More information"
          Target="_blank"
          ExtraClass="link--alt link--arrow"
        />
      </div>
    </div>
  );
}
