import React from 'react'

export default function RoomsSidebarCard(props) {
    const title = props.Data.activityTitle;
    const content = props.Data.activityDescription;
    const imageSrc = process.env.REACT_APP_CMS_ROOT + props.Data.activityImage.path + '?quality=80&width=300';

    return (
      <div className="rooms-sidebar__card">
        <div className="rooms-sidebar__card-img">
          <img src={imageSrc} alt={title} />
        </div>

        <div>
          <div className="rooms-sidebar__card-title">{title}</div>
          <div className="rooms-sidebar__card-content">{content}</div>
        </div>
      </div>
    );
}
