import React from 'react'
import { Heading } from '../index';

export default function Price(props) {
    return (
        <div className="price">
            <Heading Size="xs" PreHeading={props.Heading} ExtraClass="price__heading" />

            <div className="price__main">
                {props.MainPrice}
                <div className="price__sub">{props.Sub}</div>
            </div>
        </div>
    )
}
