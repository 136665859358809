import React from 'react'

import { Heading } from '../index';
import EventsCalendarListItem from './EventsCalendarListItem';

export default function EventsCalendarList(props) {
    const events = props.content;
    return (
        <div className="events-calendar-list">
            <Heading Size="xs" MainHeading="Special Events happening during your stay" />

            <div className="events-calendar-list__items">
                {events.map((singleEvent, i) => (
                    <EventsCalendarListItem Data={singleEvent} key={i}/>
                ))}
            </div>
        </div>
    )
}
