import React from 'react'

export default function Legend(props) {
    return (
        <legend className="legend">
            <div className="legend__main">{props.main}</div>
            {props.additional &&
                <div className="legend__additional">{props.additional}</div>
            }
        </legend>
    )
}
