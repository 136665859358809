import { useEffect, useState, useContext, useRef } from 'react';
import {
  Container,
  Grid,
  Column,
  Heading,
  RoomCard,
  Button,
  RoomsSidebar,
  Loader,
} from '../../components/index';
import { BookingContext } from '../../contexts/Booking';

import { useHistory } from 'react-router-dom';

export default function StepTwo(props) {
  const pageBottom = useRef(null);
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);
  const [content, setContent] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [activities, setActivities] = useState([]);
  const [state, dispatch] = useContext(BookingContext);

  useEffect(() => {
    const requestConfig = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        checkInDate: state.checkInDate,
        checkOutDate: state.checkOutDate,
        guests: state.guests,
        requiresDogFriendlyRoom: state.bringingDog,
        productIds: [],
        voucherCode: state.membersCode,
      }),
    };

    if (state.checkInDate === '' || state.checkOutDate === '') {
      dispatch({
        type: 'update_errors',
        value:
          'Sorry. We have either no availability for those dates or you are trying to book one night on a weekend when there is a minimum two night stay.<br/><br/>Please call 01797 225 057 to see if we can help.',
      });
      history.push('/');
    } else {
      dispatch({ type: 'update_errors', value: '' });

      async function fetchData() {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_ROOT}api/BookingStep/GetVacantRooms`,
            requestConfig
          );
          const json = await response.json();

          if (!!json.availableRooms && json.availableRooms.length) {
            let filteredRooms = json.availableRooms.filter((el) => {
              return el.content;
            });
            setRooms(filteredRooms);
            setActivities(json.content.stayActivityContent);
            setContent(json.content);
            props.helmetData({
              title: json.content.seotitle,
              description: json.content.seoDescription,
            });
            dispatch({ type: 'update__allRooms', value: filteredRooms });
            setIsLoaded(true);
          } else {
            dispatch({
              type: 'update_errors',
              value:
                'Sorry. We have either no availability for those dates or you are trying to book one night on a weekend when there is a minimum two night stay.<br/><br/>Please call 01797 225 057 to see if we can help.',
            });
            history.push('/');
          }
        } catch (e) {
          console.error(e);
        }
      }
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rateSelectionClicked = () => {
    setIsContinueDisabled(false);
  };

  const handlePageChange = () => {
    history.push('/step-3');
  };

  if (isLoaded) {
    return (
      <div className="Step StepTwo animate__animated animate__fadeIn">
        <Container>
          <Grid>
            <Column Width="50" ExtraClass="column--offset-left step-2">
              <Heading
                Size="lg"
                PreHeading={content.pageTitle}
                MainHeading={content.pageSubtitle}
              />
            </Column>
          </Grid>
        </Container>

        <Container ExtraClass="container--lg">
          <Grid>
            <Column Width="61" ExtraClass="column__tb_100">
              <Grid ExtraClass="grid--spaced-bottom-lg">
                <Column Width="100">
                  {rooms.map((room, i) => (
                    <RoomCard
                      Data={room}
                      key={i}
                      RateSelectionClicked={() =>
                        rateSelectionClicked(isContinueDisabled)
                      }
                    >
                      <Grid>
                        <Column Width="100" ExtraClass="column--mobile-center">
                          <Button
                            Label="Confirm"
                            onClick={() => handlePageChange()}
                          />
                        </Column>
                      </Grid>
                    </RoomCard>
                  ))}
                </Column>
              </Grid>

              <Grid>
                <Column
                  Width="100"
                  ExtraClass="column--mobile-center column--padding"
                >
                  <Button
                    Label="Continue"
                    ExtraClass="button--alt"
                    Disabled={!state.storedRooms.length}
                    onClick={() => handlePageChange()}
                  />
                </Column>
              </Grid>
            </Column>

            <Column>
              {activities &&
                activities.map((a) => (
                  <RoomsSidebar
                    key={a.activitiesTitle}
                    Cards={a.activities}
                    title={a.activitiesTitle}
                    subTitle={a.activitiesSubtitle}
                  />
                ))}
            </Column>
          </Grid>
        </Container>

        <div ref={pageBottom}></div>
      </div>
    );
  } else {
    return <Loader />;
  }
}
