import { Component } from 'react'

class SecureField extends Component {
  render() {
    const { fieldType, label, customClass, callback, children } = this.props

    return <div id={fieldType + '-number-container'} className={customClass} onClick={() => callback()}>
      <label className="field__label" htmlFor={fieldType + '-number'}>{label}</label>
      <div className="secure-field--input-container">
        <div className="secure-field--input" id={fieldType + '-number'}></div>
        <div className="secure-field--actions">
          <span className={'secure-field--action secure-field--action__' + fieldType + '-icon'}>
            <span className={'secure-field--action-' + fieldType + '-wrap'}>
              {children}
            </span>
          </span>
        </div>
      </div>
    </div>
  }
}

export default SecureField
