/* eslint-disable no-useless-escape */
import { Component } from 'react';
import PropTypes from 'prop-types';

import SecureField from './SecureField';

import './SecureField.css';

const getUrl = (isDev) => {
  return !isDev
    ? 'https://pay.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js'
    : 'https://pay.sandbox.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js';
};

const initalCssClass = {
  'secure-field': true,
  'secure-field__input ': true,
  'secure-field__base ': true,
  'secure-field__has-actions ': true,
  'secure-field__has-card-icon': true,
  'secure-field__has-error': false,
  'secure-field__is-recognized': false,
};

class SecureFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      cardIcon: 'card-empty',
      cvvIcon: 'cvc-empty',
      cardContainerClassNames: initalCssClass,
      cvvContainerClassNames: initalCssClass,
      hasErrors: false,
      cardDate: props.expiry,
      cardNumberHasError: false,
      cvvHasError: false,
      loading: false,
    };
  }

  componentDidMount() {
    const scriptSource = getUrl(this.props.isDev === true);

    if (document.querySelector('script[src="' + scriptSource + '"]')) {
      this.initSecureFields();
    }

    const script = document.createElement('script');
    script.src = scriptSource;
    script.onload = () => {
      this.initSecureFields();
    };
    document.body.appendChild(script);
  }

  componentWillUnmount() {
    this.secureFields.destroy();
  }

  submitForm = () => {
    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    this.props.validateCallBack();
    this.props.TsandCsCallback();

    const regExp = /[a-zA-Z]/g;

    if (this.props.expiry === '' && !regExp.test(this.props.expiry)) {
      this.setState((prevState) => ({
        ...prevState,
        hasErrors: true,
        loading: false,
      }));
    }

    if (
      this.props.state.agreedToTandCs &&
      this.props.state.firstName &&
      this.props.state.lastName &&
      this.props.state.emailAddress &&
      this.props.state.telephone &&
      this.props.state.addressLine1 &&
      this.props.state.city &&
      this.props.state.postcode
    ) {
      if (this.state.cvvHasError || this.state.cardNumberHasError) {
        this.setState((prevState) => ({
          ...prevState,
          hasErrors: true,
          loading: false
        }));
      }

      this.secureFields.submit();
    } else {
      this.setState((prevState) => ({
        ...prevState,
        loading: false
      }));
    }
  };

  render() {
    return (
      <>
        <form onSubmit={() => this.submitForm()}>
          <div className="payment-field">
            <SecureField
              fieldType="card"
              customClass="field__wrap payment-field__card-number"
              label="card number"
              placeholder="1111 2222 3333 4444"
              callback={() => this.secureFields.focus('cardNumber')}
            ></SecureField>

            <div className="field__wrap payment-field__expiry">
              <label className="field__label">Expiry date</label>
              <input
                id="cardExpiration"
                className="field"
                name="expiry"
                placeholder="MM/YY"
                type="tel"
                maxLength="5"
                value={this.state.cardDate}
                pattern="/^\d+$/"
                autoComplete="cc-exp"
                onChange={(e) => this.formatExpiryDate(e)}
              />
            </div>

            <SecureField
              fieldType="cvv"
              label="CVV"
              customClass="field__wrap payment-field__csv"
              callback={() => this.secureFields.focus('cvv')}
            ></SecureField>

            {this.state.hasErrors && (
              <div className="field__wrap__validation-message field__wrap__validation-message--failed">
                Please check your card details are correct.
              </div>
            )}
            {this.state.cardIcon === 'brands/AMX' && (
              <div className="field__wrap__validation-message field__wrap__validation-message--failed">
                Sorry, we unable to process AMEX cards, please try another card.
              </div>
            )}
          </div>

          {this.props.children}

          <button
            type="button"
            className={`button button--alt ${
              this.state.loading ? 'button--disabled' : ''
            }
              ${
                this.state.cardIcon === 'brands/AMX' ? 'button--disabled' : ''
              }`}
            disabled={
              this.state.loading || this.state.cardIcon === 'brands/AMX'
            }
            id="form-submit"
            onClick={() => this.submitForm()}
          >
            {!this.state.loading && 'Confirm Booking'}
            {this.state.loading && 'Sending'}
          </button>
        </form>
      </>
    );
  }

  initSecureFields = () => {
    const { config } = this.props;
    this.secureFields = new window.SecureFields();
    this.secureFields.initTokenize(
      config.merchantID,
      config.fields,
      config.options
    );
    this.bindSecureFieldsEvents();
  };

  formatExpiryDate = (e) => {
    let input = e.target.value;
    if (e.charCode !== 8 && input.length > 3) {
      input = input.replace('/', '');
      var first = input.substring(0, 2);
      var last = input.substring(2);
      input = `${first}/${last}`;
    }
    this.setState((prevState) => ({
      ...prevState,
      cardDate: input,
    }));
    this.props.expiryUpdateCallback(input);
  };

  bindSecureFieldsEvents = () => {
    this.secureFields.on('ready', () => {
      // Set styles manually as they're inside an iframe and out of the scope of the parent's stylesheets
      this.secureFields.setStyle(
        'cardNumber',
        'height:48px;display:block;width:100%;height:48px;padding:0 15px;padding-left:15px;font-size:18px;font-family: system-ui, sans-serif;font-weight:400;background:#fff;border: none; border-radius: 0; outline:0; -webkit-appearance: none; margin: 0;'
      );
      this.secureFields.setStyle(
        'cvv',
        'height:48px;display:block;width:100%;height:48px;padding:0 15px;padding-left:15px;font-size:18px;font-family: system-ui, sans-serif;font-weight:400;background:#fff;border: none; border-radius: 0; outline:0; -webkit-appearance: none;'
      );
      this.secureFields.setPlaceholder('cardNumber', '4242 4242 4242 4242');
      this.secureFields.setPlaceholder('cvv', '123');
    });

    // Set class names and icon when fields change
    this.secureFields.on('change', (data) => {
      let paymentMethod = data.fields.cardNumber.paymentMethod
        ? data.fields.cardNumber.paymentMethod
        : false;

      this.setState((prevState) => ({
        message: null,
        cardContainerClassNames: {
          ...prevState.cardContainerClassNames,
          'secure-field__is-recognized': !!paymentMethod,
          'secure-field__has-error': false,
        },
        cvvContainerClassNames: {
          ...prevState.cvvContainerClassNames,
          'secure-field__has-error': false,
        },
        cardIcon: paymentMethod ? 'brands/' + paymentMethod : 'card-empty',
        cvvIcon: 'cvc-empty',
      }));
    });

    // Set error icon and class name on validate failure
    this.secureFields.on('validate', (data) => {
      if (!data.fields.cardNumber.valid) {
        this.setState((prevState) => ({
          cardNumberHasError: true,
          cardContainerClassNames: {
            ...prevState.cardContainerClassNames,
            'secure-field__is-recognized': false,
            'secure-field__has-error': true,
          },
          cardIcon: 'card-error',
        }));
      }

      if (!data.fields.cvv.valid) {
        this.setState((prevState) => ({
          ...prevState,
          cvvContainerClassNames: {
            ...prevState.cvvContainerClassNames,
            'secure-field__has-error': true,
          },
          cvvIcon: 'cvc-error',
          cvvHasError: true,
        }));
      }
    });

    // Show transaction ID on success or transaction error message
    this.secureFields.on('success', (data) => {
      if (data.transactionId) {
        this.setState((prevState) => ({
          ...prevState,
          hasErrors: false
        }));

        this.props.callback({
          type: 'success',
          body: {
            transactionId: data.transactionId,
            expiry: this.state.cardDate,
          },
        });
      } else if (data.error) {
        this.props.callback({
          type: 'error',
          body: {
            message: data.error,
          },
        });
      }
    });
  };
}

SecureFields.propTypes = {
  production: PropTypes.bool,
  config: PropTypes.object,
};

SecureFields.defaultProps = {
  production: false,
  config: {},
};

export default SecureFields;
