let formFields = {};

formFields.bedrooms = {
  id: 'bedrooms',
  value: 1,
  required: true,
};

formFields.guests = {
  id: 'guests',
  value: 1,
  required: true,
};

formFields.bringMyDog = {
  id: 'bringMyDog',
  checked: true,
};

formFields.emailAddress = {
  id: 'emailAddress',
  value: '',
};

formFields.checkInDate = {
  id: 'checkInDate',
  value: new Date(),
  required: true,
};

formFields.checkOutDate = {
  id: 'checkOutDate',
  value: '',
  required: true,
};

formFields.membersCode = {
  id: 'membersCode',
  value: null,
};

export default formFields;
