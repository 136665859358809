import { useState, useContext } from 'react';
import { Link, Field } from '../index';
import { BookingContext } from "../../contexts/Booking";


export default function MembersCode(props) {
    const [localState, updateLocalState] = useState({isFormShowing: false});
    const [state, dispatch] = useContext(BookingContext);

    const handleFormChange = (e) => {
        const value = e.target.value;

        dispatch({ type: "update_membersCode", value: value });
    };

    const handleToggleClick = () => {
        updateLocalState({isFormShowing: !localState.isFormShowing})
    }

    return (
        <form onChange={(e) => handleFormChange(e)}>
            <div className="members-code">
                {!localState.isFormShowing &&
                    <Link Label="Enter your code" onClick={handleToggleClick} />
                }


                {localState.isFormShowing &&
                    <Field
                    Type="text"
                    Placeholder="Members Code"
                    WrapperClass="members-code__field"
                    Value={state.membersCode}
                    />
                }

                {props.ValidationMessage.status !== "" &&
                    <div className={`field__wrap__validation-message field__wrap__validation-message--${props.ValidationMessage.status}`}>{props.ValidationMessage.text}</div>
                }
            </div>
        </form>
    )
}
