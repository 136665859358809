import React from 'react'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export default function Slider(props) {
    const images = props.slides;

    return (
        <Carousel showIndicators={false} showThumbs={false} showStatus={false} infiniteLoop>
            {images.map((image, i) => (
                <div key={i}>
                    <img src={process.env.REACT_APP_CMS_ROOT + image.path + '?width=850&height=580&mode=crop'} alt={image.alt} />
                </div>
            ))}
        </Carousel>
    )
}
