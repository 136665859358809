import React from "react"
import { reducer, initialState } from "./Reducer"

export const BookingContext = React.createContext({
  state: initialState,
  dispatch: () => null
})

export const BookingProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (
    <BookingContext.Provider value={[ state, dispatch ]}>
    	{ children }
    </BookingContext.Provider>
  )
}
