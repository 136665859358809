import { Heading, Container, Link } from '../../components';

export default function Error() {
  return (
    <div className="api-error">
      <Container ExtraClass="container--lg">
        <Heading
          Size="mlg"
          PreHeading="Please try again later"
          MainHeading="Error"
        />

        <Link Url="/">Go back to home</Link>
      </Container>
    </div>
  );
}
