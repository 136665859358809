import React from 'react';

export default function Button(props) {
  return (
    <>
      <div
        className={`button ${props.ExtraClass ? props.ExtraClass : ''}
            ${props.Disabled ? ' button--disabled' : ''}
            ${props.IsLoader ? ' button--loader' : ''}
            ${props.Loading ? ' button--loading' : ''}`}
        onClick={props.onClick}
      >
        {props.Label}
      </div>

      {props.AdditionalText && (
        <div className="button__text">{props.AdditionalText}</div>
      )}
    </>
  );
}
