import { useContext } from 'react';
import ReactJsonSyntaxHighlighter from 'react-json-syntax-highlighter'
import { BookingContext } from '../../contexts/Booking';

export default function StateSidebar() {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(BookingContext);

  return (
    <div className="debug-sidebar">
      <ReactJsonSyntaxHighlighter obj={state}  />
    </div>
  );
};
