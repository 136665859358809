import React from 'react'
import { useLocation, Link } from 'react-router-dom'

let navLinks = [
    {
        "route": "/",
        "number": 1,
        "label": "Dates"
    },
    {
        "route": "/step-2",
        "number": 2,
        "label": "Bedrooms"
    },
    {
        "route": "/step-3",
        "number": 3,
        "label": "Details"
    },
    {
        "route": "/step-4",
        "number": 4,
        "label": "Confirm"
    }
]

const Step = ({children, ...props}) => {
    let step;

    if (props.location > props.to && props.location !== '/step-4') {
        step = <Link {...props}>{children}</Link>;
    } else {
        step = <div {...props}>{children}</div>
    }

    return step;
}

export default function NavBar() {
    const location = useLocation();

    return (
        <div className="navbar">
            {navLinks.map((NavItem, i) => (
                <Step
                    key={i}
                    className={`
                        navbar__step
                        ${(location.pathname > NavItem.route ? "navbar__step--active" : "")}
                        ${(location.pathname === NavItem.route ? "navbar__step--in-progress" : "")}
                    `}
                    location={location.pathname}
                    to={NavItem.route}
                >
                    <div className="navbar__step-number">{NavItem.number}</div>
                    <div className="navbar__step-label">{NavItem.label}</div>
                </Step>
            ))}
        </div>
    )
}

