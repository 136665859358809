import './index.scss';
import { useEffect, useState } from 'react';

import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4';

import { render } from 'react-dom';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter,
  Route,
  Switch,
  useLocation,
  withRouter,
} from 'react-router-dom';
import { BookingProvider } from './contexts/Booking/index';
import { Header, StateSidebar } from './components/index';
import { StepOne, StepTwo, StepThree, StepFour, Error } from './pages/index';
const cookies = new Cookies();

ReactGA.initialize('UA-8220260-4');
ReactGA.plugin.require('ec');

ReactGA4.initialize([
  {
    trackingId: 'G-8964VWSZNC',
    gtagOptions: {
      allowAdFeatures: true,
    },
  },
]);

const onUpdate = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);

  ReactGA4.set({ page: window.location.pathname });
  ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });
};

function _ScrollToTop(props) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

export default function App() {
  const [metaData, updateMetaData] = useState({
    title: '',
    description: '',
  });

  return (
    <>
      <Helmet>
        {metaData.title && <title>{metaData.title}</title>}
        {metaData.description && (
          <meta name="description" content={metaData.description} />
        )}
      </Helmet>

      <BookingProvider>
        <BrowserRouter onUpdate={onUpdate}>
          <Route
            path="/"
            render={({ location }) => {
              if (typeof window.ga === 'function') {
                window.ga('set', 'page', location.pathname + location.search);
                window.ga('send', 'pageview');
              }
              return null;
            }}
          />

          <ScrollToTop>
            {cookies.get('enableDebug') === 'true' && <StateSidebar />}

            <Header />

            <Route
              render={({ location }) => {
                return (
                  <div className="animate__animated animate__fadeIn">
                    <Switch location={location}>
                      <Route
                        exact
                        path={['/', '/step-1']}
                        render={() => <StepOne helmetData={updateMetaData} />}
                      />

                      <Route
                        exact
                        path="/step-2"
                        render={() => <StepTwo helmetData={updateMetaData} />}
                      />
                      <Route
                        exact
                        path="/step-3"
                        render={() => <StepThree helmetData={updateMetaData} />}
                      />
                      <Route
                        exact
                        path="/step-4"
                        render={() => <StepFour helmetData={updateMetaData} />}
                      />
                      <Route path="*" exact={true} component={Error} />
                    </Switch>
                  </div>
                );
              }}
            />
          </ScrollToTop>
        </BrowserRouter>
      </BookingProvider>
    </>
  );
}

render(<App />, document.getElementById('root'));
