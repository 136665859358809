import React from 'react';

export default function Heading({Size, ExtraClass, PreHeading, MainHeading, MainText}) {
    return (
        <div className={`heading__wrapper heading__wrapper--${Size}${ExtraClass ? " " + ExtraClass : ""}`}>
            {PreHeading &&
                <div className="heading__pre">{PreHeading}</div>
            }

            {MainHeading &&
                <div className={`heading__main heading__main--${Size}`}>{MainHeading}</div>
            }

            {MainText &&
                <div className="heading__text">{MainText}</div>
            }
        </div>
    )
}
