import React from 'react';

export default function Heading({Errors}) {
    return (
      <div className={`heading__wrapper heading__wrapper--xxs`}>
        {Errors.length > 0 && Errors.map((error, i) => (
          <div
            key={i}
            className={`heading__main heading__main--xxs heading__main--xxs-margin`}
          >
            {error}
          </div>
        ))}
      </div>
    );
}
