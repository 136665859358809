import React from 'react'

import { Link } from '../index';

export default function EventsCalendarListItem(props) {
    let data = props.Data;
    let eventStart = new Date(data.eventStart);

    return (
      <div className="events-calendar-list__item">
        <div className="events-calendar-list__item-date">
          <div className="events-calendar-list__item-day">
            {eventStart.getDate()}
          </div>
          <div className="events-calendar-list__item-month">
            {eventStart.toLocaleString('default', { month: 'short' })}
          </div>
        </div>

        <div>
          <div className="events-calendar-list__item-title">{data.eventTitle}</div>
          <Link
            Label="MORE INFORMATION"
            Url={data.eventLink}
            ExtraClass="link--alt"
          />
        </div>
      </div>
    );
}
