import React from 'react'

export default function CounterGroup(props) {
	return (
		<div className={`counter-group ${props.ExtraClass ? props.ExtraClass : ""}`}>
            {props.Heading &&
                <div className="counter-group__heading">
                    {props.Heading}
                </div>
            }

			{props.children}
		</div>
	)
}
