/* eslint-disable array-callback-return */
import { useState, useEffect, useContext, useRef } from 'react';
import {
  Loader,
  Container,
  Grid,
  Column,
  Heading,
  Field,
  PaymentField,
  StaySummary,
  BookedOnlineSubscribe,
  ErrorList,
} from '../../components/index';
import { useHistory } from 'react-router-dom';
import { BookingContext } from '../../contexts/Booking';
import { countries } from '../../data/countries';

export default function StepThree(props) {
  const childRef = useRef();
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [content, setContent] = useState([]);
  const [fieldValidationSuccess, setFieldValidationSuccess] = useState(false);
  const [state, dispatch] = useContext(BookingContext);

  const [firstNameHasError, setFirstNameHasError] = useState(false);
  const [lastNameHasError, setLastNameHasError] = useState(false);
  const [telephoneHasError, setTelephoneHasError] = useState(false);
  const [emailAddressHasError, setEmailAddressHasError] = useState(false);
  const [addressLine1HasError, setAddressLine1HasError] = useState(false);
  const [cityHasError, setCityHasError] = useState(false);
  const [postcodeHasError, setPostcodeHasError] = useState(false);
  const [countryHasError, setCountryHasError] = useState(false);

  const errorMessagePrefix = 'Please enter';

  const submitBooking = (transactionData) => {
    let transactionId = transactionData.body.transactionId;

    dispatch({ type: 'update_transactionId', value: transactionId });

    const transformRoomsData = (roomsData) => {
      let newRoomsData = [];

      roomsData.map((roomData) => {
        let rooms = roomData.rooms;

        rooms.map((r) => {
          let newDataOjb = {};
          newDataOjb.roomId = roomData.content.roomCategoryId;

          newDataOjb.availableRoomCount = roomData.availableRoomCount;
          newDataOjb.numberOfGuests = r.guests;
          newDataOjb.price = r.rate;

          let products = [];

          if (r.dogs === 1 && state.doggoPricing) {
            products.push(state.doggoPricing.id);
          }

          newDataOjb.productIds = products;

          newRoomsData.push(newDataOjb);
        });
      });

      return newRoomsData;
    };

    if (
      state.firstName === '' &&
      state.lastName === '' &&
      state.telephone === '' &&
      state.emailAddress === ''
    ) {
      let errors = [];
      if (state.firstName === '')
        errors.push(`${errorMessagePrefix} your first name`);
      if (state.lastName === '')
        errors.push(`${errorMessagePrefix} your last name`);
      if (state.emailAddress === '')
        errors.push(`${errorMessagePrefix} your email address`);
      if (state.telephone === '')
        errors.push(`${errorMessagePrefix} your telephone`);
      if (state.addressLine1 === '')
        errors.push(`${errorMessagePrefix} the first line of your address`);
      if (state.city === '') errors.push(`${errorMessagePrefix} your city`);
      if (state.postcode === '')
        errors.push(`${errorMessagePrefix} your postcode`);

      setFieldValidationSuccess(false);
      dispatch({ type: 'update_errors', value: errors });
    } else {
      setFieldValidationSuccess(true);
      const expiryDate = state.expiry.split('/');
      const formattedExpiryDate = `20${expiryDate[1]}-${expiryDate[0]}`;

      async function submitBookingRequest() {
        const requestConfig = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            leadCustomer: {
              emailAddress: state.emailAddress,
              firstName: state.firstName,
              lastName: state.lastName,
              mobile: state.telephone,
              notes: state.notes,
              addressLine1: state.addressLine1,
              addressLine2: state.addressLine2,
              city: state.city,
              postalCode: state.postcode,
              nationalityCode: state.country,
            },
            booker: null,
            reservation: {
              startDate: state.checkInDate + 'T00:00:00.000Z',
              endDate: state.checkOutDate + 'T00:00:00.000Z',
              rooms: transformRoomsData(state.allRooms),
              voucherCode: state.membersCode,
              notes: state.notes,
            },
            paymentDetails: {
              paymentGatewayData: transactionId,
              expiration: formattedExpiryDate,
              holderName: `${state.firstName} ${state.lastName}`,
            },
            browserInfo: {
              screenWidth: 1920,
              screenHeight: 1080,
              colorDepth: 24,
              userAgent: 'PostmanRuntime/7.28.0',
              language: 'en-GB',
              javaEnabled: false,
              timeZoneOffset: -60,
            },
          }),
        };

        const response = await fetch(
          `${process.env.REACT_APP_API_ROOT}api/bookingstep/CreateReservation`,
          requestConfig
        );
        const json = await response.json();

        if (json.id) {
          childRef.current.subscribeEmail();

          setTimeout(() => {
            history.push('/step-4?resId=' + json.id);
          }, 1000);
        } else {
          dispatch({
            type: 'update_errors',
            value:
              'There has been a problem submitting your booking, please try again later.',
          });
        }
      }

      submitBookingRequest();
    }
  };

  const validateFields = () => {
    if (state.firstName === '') setFirstNameHasError(true);
    if (state.lastName === '') setLastNameHasError(true);
    if (state.telephone === '') setTelephoneHasError(true);
    if (state.emailAddress === '') setEmailAddressHasError(true);
    if (state.addressLine1 === '') setAddressLine1HasError(true);
    if (state.city === '') setCityHasError(true);
    if (state.postcode === '') setPostcodeHasError(true);
    if (state.country === '') setCountryHasError(true);

    setFieldValidationSuccess(false);

    let errors = [];
    if (state.firstName === '')
      errors.push(`${errorMessagePrefix} your first name`);
    if (state.lastName === '')
      errors.push(`${errorMessagePrefix} your last name`);
    if (state.emailAddress === '')
      errors.push(`${errorMessagePrefix} your email address`);
    if (state.telephone === '')
      errors.push(`${errorMessagePrefix} your telephone`);
    if (state.addressLine1 === '')
      errors.push(`${errorMessagePrefix} the first line of your address`);
    if (state.city === '') errors.push(`${errorMessagePrefix} your city`);
    if (state.postcode === '')
      errors.push(`${errorMessagePrefix} your postcode`);

    dispatch({ type: 'update_errors', value: errors });
  };

  useEffect(() => {
    if (state.checkInDate === '' || state.checkOutDate === '') {
      history.push('/');
      setIsLoaded(true);
    } else {
      const requestConfig = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          dateFrom: state.checkInDate,
          dateTo: state.checkOutDate,
        }),
      };

      async function fetchData() {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_ROOT}api/BookingStep/GetPaymentForm`,
            requestConfig
          );
          const json = await response.json();
          setContent(json.content);
          props.helmetData({
            title: json.content.seotitle,
            description: json.content.seoDescription,
          });
          setIsLoaded(true);
        } catch (e) {
          console.error(e);
        }
      }
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoaded) {
    return (
      <div className="Step StepThree animate__animated animate__fadeIn">
        <Container ExtraClass="container--narrow">
          <Grid ExtraClass="grid--spaced-bottom-lg">
            <Column Width="35" ExtraClass="column--offset-left step-3">
              <Heading
                Size="lg"
                PreHeading={content.pageTitle}
                MainHeading={content.pageSubtitle}
              />
            </Column>
          </Grid>
        </Container>

        <Container>
          <Grid Justify="space-between">
            <Column Width="60">
              {state.errors.length > 0 && <ErrorList Errors={state.errors} />}
              <Heading Size="md" MainHeading={content.yourDetailsTitle} />

              <form>
                <Grid ExtraClass="grid--spaced-bottom-xs grid--gutters">
                  <Column Width="50">
                    <Field
                      Label="First name"
                      Type="text"
                      Name="firstName"
                      Required="required"
                      ErrorMessage="Please enter your first name"
                      HasError={firstNameHasError}
                      Value={state.firstName}
                      onChange={(e) =>
                        dispatch({
                          type: 'update_firstName',
                          value: e.target.value,
                        })
                      }
                    />
                  </Column>

                  <Column Width="50">
                    <Field
                      Label="Last name"
                      Type="text"
                      Name="lastName"
                      Required="required"
                      ErrorMessage="Please enter your last name"
                      HasError={lastNameHasError}
                      Value={state.lastName}
                      onChange={(e) =>
                        dispatch({
                          type: 'update_lastName',
                          value: e.target.value,
                        })
                      }
                    />
                  </Column>

                  <Column Width="50">
                    <BookedOnlineSubscribe
                      ref={childRef}
                      emailField={
                        <Field
                          Label="Email address"
                          Type="email"
                          Name="emailAddress"
                          Value={
                            state.emailAddress !== '' ? state.emailAddress : ''
                          }
                          Required="required"
                          ErrorMessage="Please enter a valid email address"
                          HasError={emailAddressHasError}
                          onChange={(e) =>
                            dispatch({
                              type: 'update_email',
                              value: e.target.value,
                            })
                          }
                        />
                      }
                    ></BookedOnlineSubscribe>
                  </Column>

                  <Column Width="50">
                    <Field
                      Label="Telephone"
                      Type="tel"
                      Name="telephone"
                      Required="required"
                      ValidateAs="numbersOnly"
                      ErrorMessage="Please enter your telephone number"
                      HasError={telephoneHasError}
                      Value={state.telephone}
                      onChange={(e) =>
                        dispatch({
                          type: 'update_telephone',
                          value: e.target.value,
                        })
                      }
                    />
                  </Column>

                  <Column Width="50">
                    <Field
                      Label="Address Line 1"
                      Type="text"
                      Name="addressLine1"
                      Required="required"
                      ErrorMessage="Please enter the first line of your address"
                      HasError={addressLine1HasError}
                      Value={state.addressLine1}
                      onChange={(e) =>
                        dispatch({
                          type: 'update_addressLine1',
                          value: e.target.value,
                        })
                      }
                    />
                  </Column>

                  <Column Width="50">
                    <Field
                      Label="Address Line 2"
                      Type="text"
                      Name="addressLine2"
                      Value={state.addressLine2}
                      onChange={(e) =>
                        dispatch({
                          type: 'update_addressLine2',
                          value: e.target.value,
                        })
                      }
                    />
                  </Column>

                  <Column Width="50">
                    <Field
                      Label="City"
                      Type="text"
                      Name="city"
                      Required="required"
                      ErrorMessage="Please enter your city"
                      HasError={cityHasError}
                      Value={state.city}
                      onChange={(e) =>
                        dispatch({
                          type: 'update_city',
                          value: e.target.value,
                        })
                      }
                    />
                  </Column>

                  <Column Width="50">
                    <Field
                      Label="Postcode"
                      Type="text"
                      Name="postcode"
                      Required="required"
                      ErrorMessage="Please enter your postcode"
                      HasError={postcodeHasError}
                      Value={state.postcode}
                      onChange={(e) =>
                        dispatch({
                          type: 'update_postcode',
                          value: e.target.value,
                        })
                      }
                    />
                  </Column>

                  <Column Width="50">
                    <Field
                      Label="Country"
                      Type="select"
                      Name="country"
                      Required="required"
                      ErrorMessage="Please select a country"
                      Options={countries}
                      Value={state.country}
                      HasError={countryHasError}
                      onChange={(e) =>
                        dispatch({
                          type: 'update_country',
                          value: e.target.value,
                        })
                      }
                    />
                  </Column>

                  <Column Width="100">
                    <Field
                      Label="Any additional requests for your stay"
                      Type="textarea"
                      Name="additionalRequests"
                      Value={state.notes}
                      onChange={(e) =>
                        dispatch({
                          type: 'update_notes',
                          value: e.target.value,
                        })
                      }
                    />
                  </Column>
                </Grid>
              </form>

              <Heading Size="md" MainHeading="Payment" />

              <PaymentField
                transactionIdCallback={(transId) => submitBooking(transId)}
                validationCallback={() => validateFields()}
                validationPassed={fieldValidationSuccess}
                termsAndConditionsLink={content.termsAndConditionsLink}
              />
            </Column>

            <Column Width="30">
              <StaySummary content={content}></StaySummary>
            </Column>
          </Grid>
        </Container>
      </div>
    );
  } else {
    return <Loader />;
  }
}
