import { Heading, Container } from '../index';

export default function ApiError() {
  return (
    <div className="api-error">
      <Container ExtraClass="container--lg">
        <Heading
          Size="mlg"
          PreHeading="Please try again later"
          MainHeading="Server Error: 500"
        />
      </Container>
    </div>
  );
}
