import {
  React,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  createRef,
} from 'react';
import { BookingContext } from '../../contexts/Booking';
import { BookingCounter, BookingCounterGroup } from '../index';
import RateWhatsIncluded from './RateWhatsIncluded';

export default function RatesList(props) {
  const [state, dispatch] = useContext(BookingContext);
  const [selectedRadio, setSelectedRadio] = useState();

  const [guestsInThisRoom, updateGuestsInThisRoom] = useState(
    state.guests > 1 ? 2 : 1
  );
  const [dogsInThisRoom, updateDogsInThisRoom] = useState(0);
  const roomKey = props.Index;
  const content = props.Content;
  const createRoomRateId = useCallback(
    (rateId) => {
      return props.RoomId + '---' + rateId + '---' + roomKey;
    },
    [props.RoomId, roomKey]
  );

  const ratesRef = useRef(
    props.Rates.map((option) => createRef(createRoomRateId(option.rateId)))
  );

  useEffect(() => {
    if (props.SelectedRate) {
      let radioId = createRoomRateId(props.SelectedRate);
      setSelectedRadio(radioId);
    }
  }, [createRoomRateId, props.SelectedRate]);

  const onRadioChange = (
    rateId,
    rateName,
    rateDescription,
    totalAmount,
    averageAmount,
    rateKey,
    removeData
  ) => {
    if (!removeData) {
      dispatch({
        type: 'update_selectedRooms',
        value: {
          roomId: props.RoomId,
          roomKey: roomKey,
          guests: guestsInThisRoom,
          dogs: dogsInThisRoom,
          rate: {
            rateId: rateId,
            rateName: rateName,
            rateDescription: rateDescription,
            totalAmount: totalAmount,
            averageAmount: averageAmount,
          },
          content: content,
        },
      });

      dispatch({
        type: 'update_rootSelectedRooms',
        value: {
          roomId: props.RoomId,
          roomKey: roomKey,
          guests: guestsInThisRoom,
          dogs: dogsInThisRoom,
          rate: {
            rateId: rateId,
            rateName: rateName,
            rateDescription: rateDescription,
            totalAmount: totalAmount,
            averageAmount: averageAmount,
          },
          content: content,
        },
      });

      let radioId = createRoomRateId(rateId);
      setSelectedRadio(radioId);
    } else {
      setSelectedRadio();

      dispatch({
        type: 'remove_selectedRoom',
        value: {
          rateId: rateId,
          roomId: props.RoomId,
          rateKey: rateKey,
        },
      });
    }
  };

  const updateGuestsAndRates = (val) => {
    dispatch({ type: 'update_guests', value: val });
    updateGuestsInThisRoom(val);
  };

  const updateDoggos = (newDoggoCount) => {
    updateDogsInThisRoom(newDoggoCount);
  };

  return (
    <form>
      <BookingCounterGroup>
        <BookingCounter
          Label={`Bedroom ${
            props.Index + 1
          }: Number of guests`}
          Field="guests"
          Value={guestsInThisRoom}
          callback={(val) => updateGuestsAndRates(val)}
          MaxValue={2}
        />

        {content.isDogFriendly && state.doggoPricing && (
          <BookingCounter
            Label="Number of dogs"
            ExtraClass="counter--sm"
            Field="numberOfDogs"
            RoomId={props.RoomId}
            Value={dogsInThisRoom}
            callback={(newDoggoCount) => updateDoggos(newDoggoCount)}
            MaxValue={1}
          />
        )}
      </BookingCounterGroup>
      <div className="rates-list">
        <div className="rates-list__heading">
          Please select one of the following options:
        </div>

        <div className="rates-list__items">
          {props.Rates.map((option, index) => (
            <div
              className={`rates-list__item ${
                option.addLineSeperation ? 'rates-list__item--seperator' : ''
              }`}
              key={index}
            >
              <div className="rates-list__item-content">
                <div className="rates-list__item-header">
                  <div className="rates-list__item-name">{option.rateName}</div>
                  {!option.hideDescription && option.rateDescription && (
                    <RateWhatsIncluded content={option.rateDescription} />
                  )}
                </div>

                <div className="rates-list__item-price">
                  £{(Math.round(option.averageAmount * 100) / 100).toFixed(0)}
                  <div className="rates-list__item-price-uom">per night</div>
                </div>
              </div>

              <div className="rates-list__item-info">
                <label
                  className={`radio-button ${
                    selectedRadio === createRoomRateId(option.rateId)
                      ? 'radio-button--active'
                      : ''
                  }`}
                  htmlFor={createRoomRateId(option.rateId)}
                >
                  <input
                    type="radio"
                    name="radio-button-group"
                    defaultChecked={
                      selectedRadio === createRoomRateId(option.rateId)
                    }
                    id={createRoomRateId(option.rateId)}
                    ref={ratesRef.current[index]}
                    data-waschecked="false"
                    onClick={() => {
                      let deseleting = false;
                      ratesRef.current.map(
                        (cb) => (cb.current.dataset.wasChecked = 'false')
                      );
                      const checkboxRef = ratesRef.current[index];
                      const wasChecked =
                        checkboxRef.current.dataset.waschecked === 'true';

                      if (wasChecked === true) {
                        // checkbox has been unchecked
                        deseleting = true;
                        checkboxRef.current.dataset.waschecked = false;
                      } else {
                        // checkbox has been checked
                        checkboxRef.current.dataset.waschecked = true;
                      }

                      onRadioChange(
                        option.rateId,
                        option.rateName,
                        option.rateDescription,
                        option.totalAmount,
                        option.averageAmount,
                        roomKey,
                        deseleting
                      );

                      props.UpdateOnClick(
                        state.rooms === state.selectedRooms ? true : false
                      );
                    }}
                    className="radio-button__input"
                  />
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    </form>
  );
}
