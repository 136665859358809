import { useState, useContext, useEffect } from 'react';
import {
  Card,
  Slider,
  Button,
  BookingCounter,
  BookingCounterGroup,
  Loader,
} from '../index';

import { BookingContext } from '../../contexts/Booking';
import dogIcon from '../../assets/images/icons/dog.svg';
import arrowDown from '../../assets/images/icons/arrow-down.svg';

import RatesList from '../RatesList/RatesList';

export default function RoomCard(props) {
  const [isDescriptionShowing, setIsDescriptionShowing] = useState(false);
  const [isSelectOverlayShowing, setIsSelectOverlayShowing] = useState(false);
  const [descriptionToggleLabel, setDescriptionToggleLabel] = useState('more');
  const [selectOverlayLabel, setselectOverlayLabel] = useState('Select');
  const [isContinueButtonShowing, setIsContinueButtonShowing] = useState(false);
  const [selectedRate, updatedSelectedRate] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(BookingContext);

  const getFromPrice = (data) => {
    const prices = data.price;
    let lowestPrice = prices.reduce((prev, curr) =>
      prev.averageAmount < curr.averageAmount ? prev : curr
    );
    lowestPrice = lowestPrice.averageAmount.toString().split('.');

    return lowestPrice;
  };

  let room = props.Data;
  const fromPrice = getFromPrice(props.Data);
  const content = room.content;

  useEffect(() => {
    if (state.storedRooms.length > 0) {
      const theseRooms = state.storedRooms;
      const roomId = room.roomId;
      const foundRooms = theseRooms.filter((r) => r.roomId === roomId);

      if (!foundRooms.length) {
        return;
      } else {
        if (foundRooms[0].selectedRooms !== undefined) {
          foundRooms[0].selectedRooms.forEach((r) => {
            room.numberOfGuests = foundRooms[0].selectedRooms.length || 1;

            var updated = selectedRate;
            updated[r.roomKey] = r.rate.rateId;
            updatedSelectedRate(updated);

            setIsSelectOverlayShowing(true);
            setselectOverlayLabel(
              isSelectOverlayShowing ? 'Unselect Room' : 'Select'
            );
            setIsContinueButtonShowing(true);
          });
        }
      }
    }
  }, [isSelectOverlayShowing, room, selectedRate, state.storedRooms]);

  const handleOverlayToggle = () => {
    if (isSelectOverlayShowing) {
      // overlay is about to get hidden
      setIsContinueButtonShowing(false)
      room.numberOfGuests = 1;
      dispatch({type: 'update_deselectRoom', value: room.roomId});
    }
    setIsSelectOverlayShowing(!isSelectOverlayShowing);
    setselectOverlayLabel(!isSelectOverlayShowing ? 'Unselect Room' : 'Select');
  };

  const handleDescriptionToggle = (e) => {
    if (!isDescriptionShowing) {
      setIsDescriptionShowing(true);
      setDescriptionToggleLabel('less');
    } else {
      setIsDescriptionShowing(false);
      setDescriptionToggleLabel('more');
    }
  };

  const handleRateSelectionClick = (v) => {
    setIsContinueButtonShowing(true);
    props.RateSelectionClicked();
  };

  const bedroomsHasUpdated = (dir) => {
    if (dir === 'down') {
      const roomObjectExists = state.storedRooms.some(
        (r) => r.roomId === room.roomId
      );

      if (roomObjectExists) {
        const roomObject = state.storedRooms.filter(
          (r) => r.roomId === room.roomId
        )[0];
        const roomObjectsRooms = roomObject.selectedRooms;

        if (roomObjectsRooms.length > 1) {
          setIsContinueButtonShowing(true);
        } else {
          setIsContinueButtonShowing(false);
        }
      }
    }
  };

  if (content) {
    const images = content.roomImage;

    return (
      <>
        <div className="room-card">
          <div className="room-card__gallery">
            <Slider slides={images} />
          </div>

          <div className="room-card__content">
            <div className="room-card__content-inner">
              <div className="room-card__name">{room.content.roomName}</div>

              {room.content.isDogFriendly && (
                <div className="room-card__dog">
                  <img src={dogIcon} alt="Dog friendly icon" />
                  Dog friendly room
                </div>
              )}

              {room.content.roomDescription && (
                <>
                  <div
                    className={`room-card__description ${
                      isDescriptionShowing
                        ? 'room-card__description--active'
                        : ''
                    }`}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: room.content.roomDescription,
                      }}
                    ></div>
                  </div>

                  <div
                    className={`room-card__description-toggle ${
                      isDescriptionShowing
                        ? 'room-card__description-toggle--active'
                        : ''
                    }`}
                    onClick={() => handleDescriptionToggle()}
                  >
                    <img src={arrowDown} alt="Description toggle arrow" />
                    Show {descriptionToggleLabel}
                  </div>
                </>
              )}
            </div>

            {room.price && (
              <>
                <div className="room-card__pricing-wrap">
                  {fromPrice && (
                    <div className="room-card__pricing">
                      <div className="room-card__pricing-label">From</div>
                      <div className="room-card__pricing-price">
                        £{fromPrice[0]}
                        <div className="room-card__pricing-qty">per night</div>
                      </div>
                    </div>
                  )}

                  <Button
                    Label={selectOverlayLabel}
                    ExtraClass="button--alt"
                    onClick={() => handleOverlayToggle()}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        {isSelectOverlayShowing && (
          <Card ExtraClass="room-card__select-overlay">
            <BookingCounterGroup ExtraClass="counter-group--number-of-rooms">
              <BookingCounter
                Label="Number of bedrooms"
                Field="thisBedrooms"
                RoomId={room.roomId}
                Value={room.numberOfGuests}
                MaxValue={2}
                callback={(dir) => bedroomsHasUpdated(dir)}
              />
            </BookingCounterGroup>

            {[...Array(room.numberOfGuests)].map((e, i) => (
              <div className="room-card__rate" key={i}>
                <RatesList
                  Index={i}
                  RoomId={room.roomId}
                  Rates={room.price}
                  Content={room.content}
                  SelectedRate={selectedRate[i]}
                  UpdateOnClick={(v) => handleRateSelectionClick(v)}
                />
              </div>
            ))}

            {isContinueButtonShowing && props.children}
          </Card>
        )}
      </>
    );
  } else {
    return <Loader />;
  }
}
