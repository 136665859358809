import { useRef, useContext, forwardRef, useImperativeHandle } from 'react';

import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { BookingContext } from '../../contexts/Booking';

const url =
  '//thegallivant.us8.list-manage.com/subscribe/post?u=5cf660c0d46e56a736e6cffa4&amp;id=42a3e193d4';

const CustomForm = forwardRef(({ status, message, onValidated, emailField }, ref) => {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(BookingContext);
  useImperativeHandle(ref, () => ({
    submit() {
      onValidated({
        EMAIL: state.emailAddress
      });
    }
  }));

  return (
    <div>
      {emailField}
    </div>
  );
});

const BookedOnlineSubscribe = forwardRef(({ emailField }, ref) => {
  const childRef = useRef();

  useImperativeHandle(ref, () => ({
    subscribeEmail(tag) {
      childRef.current.submit(tag);
    },
  }));

  return (
    <>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
            emailField={emailField}
            ref={childRef}
          />
        )}
      />
    </>
  );
});

export default BookedOnlineSubscribe;
