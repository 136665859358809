export const ValidationPatterns = [
  {
    match: 'numbersOnly',
    pattern: '[0-9 ]*',
  },
  {
    match: 'cvv',
    pattern: '[0-9]{1,3}',
  },
  {
    match: 'creditCard',
    pattern: '[0-9]{1,16}',
  },
  {
    match: 'expiryDate',
    pattern: '[0-9/]{1,6}',
  },
];
