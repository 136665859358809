import { useContext } from 'react';
import { BookingContext } from '../../contexts/Booking';

export default function Counter({
  ExtraClass,
  Field,
  Label,
  Value,
  RoomId,
  MaxValue,
  callback,
}) {
  const [state, dispatch] = useContext(BookingContext);

  const getNewCount = (dir) => {
    if (dir === 'up') {
      switch (Field) {
        case 'thisBedrooms':
          return Value < MaxValue ? Value + 1 : MaxValue;

        case 'rootGuests':
          return Value < state.rooms * 2 ? Value + 1 : state.rooms * 2;

        case 'guests':
          return Value < 2 ? Value + 1 : 2;

        case 'numberOfDogs':
          return Value === 0 ? Value + 1 : 1;

        default:
          return Value + 1;
      }
    } else {
      if (Field === 'thisBedrooms') {
        return Value === 0 ? 0 : Value - 1;
      } else {
        return Value === 1 ? 1 : Value - 1;
      }
    }
  };

  const updateCounter = (dir, roomId) => {
    const newCount = getNewCount(dir);

    switch (Field) {
      case 'rootGuests':
        if (dir === 'up') {
          dispatch({ type: 'update_guests', value: newCount });
          dispatch({ type: 'update_rooms', value: newCount });
        } else {
          let maxRooms = null;
          let currentRoomsCount = state.rooms;
          let currentGuestCount = state.guests;

          switch (newCount) {
            case 10:
              maxRooms = 5;
              break;

            case 8:
              maxRooms = 4;
              break;

            case 6:
              maxRooms = 3;
              break;

            case 4:
              maxRooms = 2;
              break;

            case 2:
              maxRooms = 1;
              break;

            default:
              break;
          }

          let newRoomCount =
            currentRoomsCount > maxRooms ? maxRooms : currentRoomsCount;
          let newGuestCount =
            currentGuestCount <= currentRoomsCount
              ? currentGuestCount
              : currentGuestCount - 1;

          dispatch({ type: 'update_guests', value: newGuestCount });
          dispatch({ type: 'update_rooms', value: newRoomCount });
        }
        break;

      case 'guests':
        callback(newCount);
        break;

      case 'numberOfDogs':
        callback(newCount);
        break;

      case 'thisBedrooms':
        callback(dir);
        dispatch({
          type: 'update_thisBedrooms',
          value: newCount,
          roomId: roomId,
        });
        break;

      default:
        break;
    }
  };

  return (
    <div className={`counter ${ExtraClass ? ExtraClass : ''}`}>
      <div
        className="counter__label"
        dangerouslySetInnerHTML={{
          __html: Label,
        }}
      ></div>

      <div className="counter__field">
        <div
          className="counter__field-button counter__field-button--down"
          onClick={() => updateCounter('down', RoomId)}
        ></div>
        <div className="counter__field-value">{Value}</div>
        <div
          className={`counter__field-button counter__field-button--up ${
            Value === MaxValue ? 'counter__field-button--disabled' : ''
          }`}
          onClick={() => updateCounter('up', RoomId)}
        ></div>
      </div>
    </div>
  );
}
