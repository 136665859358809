import { useContext } from 'react';
import moment from 'moment';
import { Heading, Rte, Rule, Price } from '../../components/index';
import { BookingContext } from '../../contexts/Booking';

export default function StaySummary(props) {
  const [state] = useContext(BookingContext);
  const content = props.content;
  const arrivingOn = moment(state.checkInDate);
  const arrivingOnFormatted = arrivingOn.format('Do MMM YYYY');
  const leavingOn = moment(state.checkOutDate);
  const leavingOnFormatted = leavingOn.format('Do MMM YYYY');
  const totalNights = leavingOn.diff(arrivingOn, 'days');
  let requestedRooms = [];

  state.storedRooms.forEach((r) => {
    if (r.selectedRooms.length) {
        r.selectedRooms.forEach((r) => {
            requestedRooms.push(r);
        });
    }
  });

  const calculatedTotal = () => {
    let reducer = (acc, item) => {
      let total = acc + item.rate.averageAmount * totalNights;
      let returnPrice = 0;

      if (item.dogs === 1 && state.doggoPricing) {
        returnPrice = total + state.doggoPricing.price * totalNights;
      } else {
        returnPrice = total;
      }

      return returnPrice;
    };

    let totalPrice = requestedRooms.reduce(reducer, 0);
    return (Math.round(totalPrice * 100) / 100).toFixed(0);
  };

  function RoomsSummary() {
    return requestedRooms.map((room) => (
      <div
        className="stay-summary__row underline"
        key={room.content.roomName + room.roomId}
      >
        <div className="stay-summary__col">
          <Heading
            Size="xs"
            MainHeading={room.content.roomName}
            ExtraClass="heading__wrapper--secondary"
          />
        </div>
        <div className="stay-summary__col text-right">
          <Heading
            Size="xs"
            MainHeading={
              room.guests + ' guest' + (room.guests !== 1 ? 's' : '')
            }
            ExtraClass="heading__wrapper--secondary"
          />
        </div>
        <div className="stay-summary__col stay-summary__col--100">
          <Heading
            Size="xs"
            MainText={
              room.rate.rateName +
              ' - £' +
              room.rate.averageAmount.toFixed(0) +
              ' per night'
            }
            ExtraClass="heading__wrapper--secondary heading__wrapper--tight"
          />
          {room.dogs === 1 && state.doggoPricing && (
            <Heading
              Size="xs"
              MainText={
                state.doggoPricing.name +
                ' - £' +
                state.doggoPricing.price +
                ' per night'
              }
              ExtraClass="heading__wrapper--secondary"
            />
          )}
        </div>
      </div>
    ));
  }

  return (
    <div className="stay-summary">
      <Heading Size="xs" MainHeading={content.aSummaryOfYourStay} />

      <Heading Size="xs" PreHeading={content.dates} />

      <div className="stay-summary__row underline">
        <div className="stay-summary__col">
          <Heading
            Size="xs"
            MainHeading={content.arrivingOn}
            ExtraClass="heading__wrapper--secondary"
          />
        </div>
        <div className="stay-summary__col text-right">
          <Heading
            Size="xs"
            MainHeading={arrivingOnFormatted}
            ExtraClass="heading__wrapper--secondary"
          />
        </div>
      </div>

      <div className="stay-summary__row underline">
        <div className="stay-summary__col">
          <Heading
            Size="xs"
            MainHeading={content.leavingOn}
            ExtraClass="heading__wrapper--secondary"
          />
        </div>
        <div className="stay-summary__col text-right">
          <Heading
            Size="xs"
            MainHeading={leavingOnFormatted}
            ExtraClass="heading__wrapper--secondary"
          />
        </div>
      </div>

      <Heading Size="xs" PreHeading={content.bedrooms} />

      <RoomsSummary />

      <Price
        Heading={content.totalToPay}
        MainPrice={'£' + calculatedTotal()}
        Sub="inc. VAT"
      />

      <Rule />

      <Heading Size="xs" PreHeading={content.cancellationPolicyTitle} />
      <Rte>
        <p
          className="rte--sm rte--no-space"
          dangerouslySetInnerHTML={{
            __html: content.cancellationPolicyDescription,
          }}
        ></p>
      </Rte>

      {props.children}
    </div>
  );
}
