import { useEffect, useState, useContext, useRef } from 'react';
import moment from 'moment';
import { imageCrops } from '../../data/config';
import {
  Container,
  Grid,
  Column,
  Heading,
  Card,
  Legend,
  Field,
  BookingCheckbox,
  Link,
  Button,
  Alert,
  DatePicker,
  MembersCode,
  Loader,
  MembershipEnquirySubscribe,
  ApiError,
  Modal
} from '../../components/index';
import { useHistory } from 'react-router-dom';
import formFields from '../../content/formFields';
import { BookingContext } from '../../contexts/Booking';

export default function StepOne(props) {
  const childRef = useRef();
  const history = useHistory();
  const [fields] = useState(formFields);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useContext(BookingContext);
  const [content, setContent] = useState([]);
  const [events, setEvents] = useState([]);
  const [voucherResponse, setVoucherResponse] = useState({
    status: '',
    text: '',
  });

  useEffect(() => {
    if (state.emailAddress) {
      dispatch({ type: 'update_clearState' });
    }

    let today = moment().format('YYYY-MM-DD');
    let todayPlus60 = moment(today, 'YYYY-MM-DD').add(60, 'days');
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ROOT}api/BookingStep/StartBookingProcess`
        );
        const json = await response.json();
        setContent(json.content);
        props.helmetData({
          title: json.content.seotitle,
          description: json.content.seoDescription,
        });
        dispatch({ type: 'update_doggoPricing', value: json.dogProduct });
        setIsLoaded(true);
      } catch (e) {
        console.error(e);
        setHasServerError(true);
        props.emitHasServerError(true);
      }
    }
    fetchData();

    async function fetchEvents() {
      setVoucherResponse({ status: '', text: '' });
      const requestConfig = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          dateFrom: today,
          dateTo: todayPlus60,
        }),
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ROOT}api/events/search`,
          requestConfig
        );
        const json = await response.json();

        if (json.eventDates.length) {
          setEvents(json.eventDates);
        }
      } catch (e) {
        console.error(e);
      }
    }
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function validateVoucher(voucherCode) {
    const requestConfig = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        voucherCode: voucherCode,
      }),
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}api/booking/ValidateVoucher`,
      requestConfig
    );
    const json = await response.json();
    return json;
  }

  async function handlePageChange() {
    setIsLoading(true);
    if (state.membersCode !== '') {
      const voucherResponse = await validateVoucher(state.membersCode);
      const voucherIsValid = voucherResponse.voucherIsValid;
      if (voucherIsValid) {
        setVoucherResponse({
          status: 'success',
          text: 'Member code applied successfully.',
        });

        setTimeout(() => {
          // wait a little bit then move to next step.
          setIsLoading(false);
          history.push('/step-2');
        }, 1200);
      } else {
        setIsLoading(false);
        setVoucherResponse({
          status: 'failed',
          text: 'Member code invalid.',
        });
      }
    } else if (state.emailAddress !== '') {
      childRef.current.subscribeEmail();
      setTimeout(() => {
        setIsLoading(false);
        history.push('/step-2');
      }, 500);
    } else {
      setIsLoading(false);
      history.push('/step-2');
    }
  }

  function handleFormChange(e) {
    const el = e.target.name;
    const value = e.target.value;

    switch (el) {
      case 'emailAddress':
        dispatch({ type: 'update_email', value: value });
        break;

      default:
        break;
    }
  }

  if (hasServerError) {
    return <ApiError />;
  } else if (isLoaded) {
    return (
      <div className="Step StepOne animate__animated animate__fadeIn">
        <Container>
          <Grid>
            <Column Width="35" ExtraClass="column--offset-left step-1">
              <Heading
                Size="lg"
                PreHeading={content.pageTitle}
                MainHeading={content.pageSubtitle}
              />
            </Column>
          </Grid>

          <Grid
            ExtraClass="grid--overlap-lg grid--flip-columns grid--spaced-bottom"
            Justify="end"
          >
            <Column Width="65">
              <img
                src={
                  process.env.REACT_APP_CMS_ROOT +
                  content.guestsImage.path +
                  imageCrops.stepOne
                }
                alt={content.guestsImage.alt}
              />
            </Column>
          </Grid>

          <Grid ExtraClass="grid--overlap-xxl">
            <Column Width="35">
              <Heading
                Size="mlg"
                PreHeading={content.datesTitle}
                MainHeading={content.datesSubtitle}
              />
            </Column>
          </Grid>

          <Grid>
            <Column Width="60">
              <Card>
                <DatePicker Events={events} />

                <Alert Content="If you’re a member or have a code please enter it here">
                  <MembersCode
                    MembersCode={fields.membersCode.value}
                    ValidationMessage={voucherResponse}
                  />
                </Alert>

                <BookingCheckbox
                  Label={
                    content.dogCheckboxLabel
                      ? content.dogCheckboxLabel
                      : 'I want to bring my dog'
                  }
                  For={fields.bringMyDog.id}
                  Checked={fields.bringMyDog.checked}
                  isChecked={state.bringingDog}
                  Field="bringmydog"
                />

                <Grid ExtraClass="grid--spaced-bottom-xs">
                  <Column>
                    <Modal
                      trigger={
                        <Link
                          ExtraClass="link__mt-0"
                          Label={content.dogDocumentDescription}
                          Icon="download"
                        />
                      }
                    />
                  </Column>
                </Grid>

                <Button
                  Label="Check Availability"
                  IsLoader={true}
                  Loading={isLoading}
                  onClick={() => handlePageChange()}
                />
              </Card>
            </Column>
          </Grid>

          <Grid
            ExtraClass="grid--overlap-xxl grid--spaced-bottom"
            Justify="end"
          >
            <Column Width="65">
              <img
                src={
                  process.env.REACT_APP_CMS_ROOT +
                  content.datesImage.path +
                  imageCrops.stepOne
                }
                alt={content.guestsImage.alt}
              />
            </Column>
          </Grid>

          <Grid ExtraClass="grid--spaced-bottom" Justify="space-between">
            <Column Width="30">
              <Heading
                Size="mlg"
                PreHeading={content.membershipTitle}
                MainHeading={content.membershipSubtitle}
              />
            </Column>

            <Column Width="50">
              <Card>
                <Legend
                  main={content.becomingAMemberTitle}
                  additional={content.becomingAMemberSubtitle}
                />

                <form onChange={(e) => handleFormChange(e)}>
                  <MembershipEnquirySubscribe
                    ref={childRef}
                    emailField={
                      <Field
                        Label={content.becomingAMemberTextBoxLabel}
                        Type="email"
                        Name={fields.emailAddress.id}
                        ErrorMessage="Please enter a valid email address"
                        ExtraClass="field--bordered"
                        Value={state.emailAddress}
                        autoComplete="off"
                      />
                    }
                  ></MembershipEnquirySubscribe>
                </form>
              </Card>
            </Column>
          </Grid>
        </Container>
      </div>
    );
  } else {
    return <Loader />;
  }
}
