import React from 'react';
import { ValidationPatterns } from '../../_utils/ValidationPatterns';
class Field extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldValue: this.props.Value !== '' ? this.props.Value : '',
      hasError: this.props.HasError !== '' ? this.props.HasError : false,
      errorMessage: this.props.ErrorMessage ? this.props.ErrorMessage : '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidUpdate(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        hasError: nextProps.HasError,
      });
    }
  }

  handleInputChange = (evt) => {
    let fieldValue;

    if (evt.target.validity.valid) {
      this.setState({ hasError: false, errorMessage: '' });
      fieldValue = evt.target.value;
    } else {
      this.setState({ hasError: true, errorMessage: evt.target.dataset.error });
      fieldValue = this.state.fieldValue;
    }

    this.setState({ fieldValue: fieldValue });
  };

  getValidationPattern = () => {
    let retrievedPattern = ValidationPatterns.filter((patt) => {
      return this.props.ValidateAs === patt.match;
    })[0];

    return retrievedPattern ? retrievedPattern.pattern : false;
  };

  render() {
    return (
      <div
        className={`field__wrap ${
          this.props.WrapperClass ? this.props.WrapperClass : ''
        } ${this.state.hasError ? 'field__wrap--has-error' : ''}`}
      >
        {this.props.Label && (
          <label htmlFor={this.props.Name} className="field__label">
            {this.props.Label}
          </label>
        )}

        {this.props.Required &&
          this.props.Name !== 'cardNumber' &&
          this.props.Name !== 'expiryDate' &&
          this.props.Name !== 'cvv' && (
            <span className="field__required">required</span>
          )}

        {this.props.Type !== 'textarea' && this.props.Type !== 'select' && (
          <input
            type={this.props.Type}
            name={this.props.Name}
            className={`field ${
              this.props.ExtraClass ? this.props.ExtraClass : ''
            }`}
            required={this.props.Required}
            placeholder={this.props.Placeholder}
            pattern={
              this.getValidationPattern() ? this.getValidationPattern() : null
            }
            data-error={this.props.ErrorMessage}
            onBlur={this.handleInputChange.bind(this)}
            defaultValue={
              this.state.fieldValue !== undefined ? this.state.fieldValue : ''
            }
            ref={this.props.Name}
            onChange={(e) => this.props.onChange(e)}
            autoComplete={this.props.autoComplete}
          />
        )}

        {this.props.Type === 'select' && (
          <select
            name={this.props.Name}
            required={this.props.Required}
            defaultValue={this.props.Value}
            className={`field ${
              this.props.ExtraClass ? this.props.ExtraClass : ''
            }`}
            onChange={(e) => this.props.onChange(e)}
          >
            {this.props.Options.map((c) => {
              return (
                <option key={c.code} value={c.code}>
                  {c.name}
                </option>
              );
            })}
          </select>
        )}

        {this.props.Type === 'textarea' && (
          <textarea
            type={this.props.Type}
            name={this.props.Name}
            className={`field ${
              this.props.ExtraClass ? this.props.ExtraClass : null
            }`}
            required={this.props.Required}
            placeholder={this.props.Placeholder}
            defaultValue={this.props.Value}
            onChange={(e) => this.props.onChange(e)}
          ></textarea>
        )}

        {this.state.hasError && (
          <div className="field__wrap__validation-message field__wrap__validation-message--failed">
            {this.state.errorMessage}
          </div>
        )}
      </div>
    );
  }
}

export default Field;
