import React from 'react';
import { Container, Logo, NavBar } from '../index';

export default function Header() {
  return (
    <Container ExtraClass="container--lg header">
      <a href="https://thegallivant.co.uk/" className="header__logo">
        <Logo />
      </a>

      <NavBar />
    </Container>
  );
}
