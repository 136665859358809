import { useState } from 'react';
import { Link, Button } from '../index';

export default function SpecialEventPopup(props) {
  const [ display, updateDisplay ] = useState(true);
  function toggleEvent(e) {
    e.preventDefault();
    e.stopPropagation();
    updateDisplay(false);
  }

  if (display) {
    return (
      <>
        <div className="special-event">
          <div className="special-event__close">
            <div className="special-event__pre-title">{props.preTitle}</div>
            <Button
              Label='x'
              onClick={toggleEvent}
              ExtraClass="button--small"
            />
          </div>
          <div className="special-event__title">{props.title}</div>
          <div className="special-event__content">{props.content}</div>
          <Link Url={props.link} Target="_blank" Label="Find Out More" ExtraClass="special-event__link" />
        </div>
      </>
    );
  }

  else {
    return null
  }
}