import { useRef, useEffect, useContext, useState } from 'react';
import moment from 'moment';
import Calendar from 'react-calendar';
import { BookingContext } from '../../contexts/Booking';

import { ReactComponent as CalendarIcon } from '../../assets/images/icons/calendar.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/images/icons/arrow-right.svg';

import SpecialEventPopup from './DatePickerSpecialEventPopup';

function OutsideDetector(props, onOutsideClick) {
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        props.onOutsideClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div className="react-calendar__wrap" ref={wrapperRef}>
      {props.children}
    </div>
  );
}

function CalendarKey() {
  return (
    <div className="date-picker__key">
      <div className="date-picker__key__icon"></div>
      <div className="date-picker__key__content">
        A Special Event is happening on this date you might be interested in
      </div>
    </div>
  );
}

export default function DatePicker(props) {
  const datePickerEnd = useRef(null);

  const today = new Date();
  const todayVal = moment().format('YYYY-MM-DD');
  const todayPlusTwoMonths = moment().add(2, 'M').format('YYYY-MM-DD');
  const todayLabel = 'Select Date';
  const tomorrowLabel = 'Select Date';

  const [state, dispatch] = useContext(BookingContext);
  const [disabledDates, updateDisabledDates] = useState([]);
  const [specialEvents, updateSpecialEvents] = useState([]);
  const [minDate, updateMinDate] = useState(new Date());
  const [maxDate, updateMaxDate] = useState();
  const [isCheckInCalendarShowing, updateIsCheckInCalendarShowing] =
    useState(false);
  const [isCheckOutCalendarShowing, updateIsCheckOutCalendarShowing] =
    useState(false);
  const [checkInLabel, updateCheckInLabel] = useState(todayLabel);
  const [checkOutLabel, updateCheckOutLabel] = useState(tomorrowLabel);
  const [checkinCalendarValue, updateCheckinCalendarValue] = useState();
  const [checkoutCalendarValue, updateCheckoutCalendarValue] = useState([]);

  useEffect(() => {
    if (state.errors !== '') {
      setTimeout(() => {
        datePickerEnd.current?.scrollIntoView({ behavior: 'smooth' });
      }, 1000);
    }

    if (state.checkInDate && state.checkOutDate) {
      updateCheckinCalendarValue(new Date(state.checkInDate));
      updateCheckoutCalendarValue(new Date(state.checkOutDate));
      updateCheckInLabel(moment(state.checkInDate).format('Do MMM YYYY'));
      updateCheckOutLabel(moment(state.checkOutDate).format('Do MMM YYYY'));
    }
  }, [state, todayVal, todayPlusTwoMonths]);

  async function fetchUnavailableDates(dateFrom, dateTo, additionalCheck) {
    const requestConfig = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        dateFrom: dateFrom + 'T00:00:01.000Z',
        dateTo: dateTo + 'T00:00:01.000Z',
        eventDescriptionLimit: 90,
      }),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}api/availability/GetAvailabilityByDate`,
        requestConfig
      );
      const json = await response.json();

      if (json.unavailableDates.length) {
        let dates = json.unavailableDates.map((date) =>
          moment(date).format('YYYY-MM-DD')
        );

        const updatedDates = disabledDates.concat(dates);

        updateDisabledDates(updatedDates);

        if (additionalCheck) {
          let start = moment(dateFrom);
          let end = moment(dateFrom).add(30, 'days');
          const datesToLoop = [];

          while (start <= end) {
            datesToLoop.push(moment(start).format('YYYY-MM-DD'));
            start = moment(start).add(1, 'days');
          }

          for (let i = 0; i < datesToLoop.length; i++) {
            let d = datesToLoop[i];

            if (disabledDates.includes(d)) {
              let newDisabledDates = updatedDates.filter((day) => day !== d);
              updateDisabledDates(newDisabledDates);

              return false;
            }
          }
        }
      }

      if (json.specialEvents) {
        updateSpecialEvents(json.specialEvents);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const handleChange = (type, value) => {
    if (type === 'checkin') {
      const checkInDate = value;
      const minDate = moment(checkInDate).add(1, 'days');

      dispatch({
        type: 'update_checkInDate',
        value: moment(value).format('YYYY-MM-DD'),
      });
      updateMinDate(new Date(minDate));
      updateCheckoutCalendarValue(checkInDate);
      updateCheckInLabel(moment(checkInDate).format('Do MMM YYYY'));
      updateCheckinCalendarValue(checkInDate);
      handleCheckInCalendarToggle();
      updateIsCheckOutCalendarShowing(true);

      let start = moment(checkInDate);
      let end = moment(checkInDate).add(30, 'days');
      const datesToLoop = [];

      while (start <= end) {
        datesToLoop.push(moment(start).format('YYYY-MM-DD'));
        start = moment(start).add(1, 'days');
      }

      for (let i = 0; i < datesToLoop.length; i++) {
        let d = datesToLoop[i];

        if (disabledDates.includes(d)) {
          updateMaxDate(new Date(d));
          let newDisabledDates = disabledDates.filter((day) => day !== d);
          updateDisabledDates(newDisabledDates);

          return false;
        }
      }
    } else if (type === 'checkout') {
      dispatch({
        type: 'update_checkOutDate',
        value: moment(value).format('YYYY-MM-DD'),
      });
      updateCheckoutCalendarValue([checkinCalendarValue, value]);
      updateCheckOutLabel(moment(value).format('Do MMM YYYY'));
      fetchEventsDuringStay(checkinCalendarValue, value);
    }
  };

  async function fetchEventsDuringStay(dateFrom, dateTo) {
    const requestConfig = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        dateFrom: dateFrom,
        dateTo: dateTo,
      }),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}api/events/search`,
        requestConfig
      );
      const json = await response.json();

      if (json.events.length) {
        const events = json.events.splice(0, 2);
        dispatch({ type: 'update_events', value: events });
      } else {
        dispatch({
          type: 'update_events',
          value: 'Sorry, there are no events during your stay.',
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  const handleCheckInCalendarToggle = () => {
    if (isCheckInCalendarShowing === false) {
      resetDates();
      dispatch({
        type: 'update_errors',
        value: '',
      });
      fetchUnavailableDates(todayVal, todayPlusTwoMonths, false);
    }
    updateIsCheckInCalendarShowing(!isCheckInCalendarShowing);
  };

  const handleCheckOutCalendarToggle = () => {
    if (state.checkInDate) {
      updateIsCheckOutCalendarShowing(!isCheckOutCalendarShowing);
    } else {
      updateIsCheckInCalendarShowing(!isCheckInCalendarShowing);
    }
  };

  const handleAddClassNames = ({ activeStartDate, date, view }) => {
    const fullDate = moment(date).format('YYYY-MM-DD');
    const events = props.Events.map((date) =>
      moment(date).format('YYYY-MM-DD')
    );
    return events.includes(fullDate) ? 'day-has-event' : null;
  };

  const handleUnavailableDates = ({ activeStartDate, date, view }) => {
    const fullDate = moment(date).format('YYYY-MM-DD');
    return disabledDates.includes(fullDate);
  };

  const resetDates = () => {
    dispatch({
      type: 'update_checkOutDate',
      value: '',
    });
    updateCheckOutLabel(tomorrowLabel);
    updateCheckInLabel(todayLabel);
    updateMinDate(today);
    updateMaxDate(null);
    updateCheckinCalendarValue(null);
    updateCheckoutCalendarValue(null);
  };

  const handleCheckInMonthNavigation = (e) => {
    const checkInDate = e.value ? e.value : e.activeStartDate;
    const checkInDateVal = moment(checkInDate).format('YYYY-MM-DD');

    const checkOutDateVal = moment(e.activeStartDate)
      .add(2, 'M')
      .startOf('month')
      .format('YYYY-MM-DD');

    if (isCheckInCalendarShowing) {
      fetchUnavailableDates(checkInDateVal, checkOutDateVal, true);
    } else if (isCheckOutCalendarShowing) {
      fetchUnavailableDates(checkInDateVal, checkOutDateVal, false);
    }
  };

  const handleSpecialEvents = (date, view) => {
    const tileDate = moment(date).format('YYYY-MM-DD');

    if (specialEvents.length > 0) {
      return specialEvents.map((evt) => {
        const thisDate = moment(evt.date).format('YYYY-MM-DD');

        if (tileDate === thisDate) {
          return (
            <SpecialEventPopup
              key={tileDate}
              preTitle={evt.subTitle}
              title={evt.title}
              content={evt.content}
              link={evt.link}
            />
          );
        } else {
          return false;
        }
      });
    }
  };

  return (
    <>
      <div className="date-picker__wrapper">
        {state.errors && (
          <div
            className="date-picker__error"
            dangerouslySetInnerHTML={{
              __html: state.errors,
            }}
          ></div>
        )}

        <div className="date-picker">
          <div
            className="date-picker__inner"
            onClick={(e) => handleCheckInCalendarToggle()}
          >
            <div className="date-picker__label">Check in</div>

            <div className="date-picker__date-trigger">
              <div className="date-picker__date-value">{checkInLabel}</div>
              <CalendarIcon />
            </div>
          </div>

          {isCheckInCalendarShowing && (
            <OutsideDetector onOutsideClick={handleCheckInCalendarToggle}>
              <Calendar
                value={checkinCalendarValue}
                showDoubleView={true}
                showNavigation={true}
                showNeighboringMonth={false}
                minDate={new Date()}
                prevLabel={<ArrowLeftIcon />}
                nextLabel={<ArrowRightIcon />}
                onActiveStartDateChange={(e) => handleCheckInMonthNavigation(e)}
                onChange={(value, event) =>
                  handleChange('checkin', value, event)
                }
                tileDisabled={handleUnavailableDates}
                tileClassName={handleAddClassNames}
                tileContent={({ date, view }) =>
                  handleSpecialEvents(date, view)
                }
              />
              <CalendarKey />
            </OutsideDetector>
          )}
        </div>

        <div className="date-picker">
          <div
            className="date-picker__inner"
            onClick={(e) => handleCheckOutCalendarToggle()}
          >
            <div className="date-picker__label">Check out</div>
            <div className="date-picker__date-trigger">
              <div className="date-picker__date-value">{checkOutLabel}</div>
              <CalendarIcon />
            </div>

            {isCheckOutCalendarShowing && (
              <OutsideDetector onOutsideClick={handleCheckOutCalendarToggle}>
                <Calendar
                  value={checkoutCalendarValue}
                  showDoubleView={true}
                  showNavigation={true}
                  showNeighboringMonth={false}
                  minDate={minDate}
                  maxDate={maxDate}
                  prevLabel={<ArrowLeftIcon />}
                  nextLabel={<ArrowRightIcon />}
                  selectRange={true}
                  onClickDay={(value, event) =>
                    handleChange('checkout', value, event)
                  }
                  tileClassName={handleAddClassNames}
                  tileDisabled={handleUnavailableDates}
                />
                <CalendarKey />
              </OutsideDetector>
            )}
          </div>
        </div>
      </div>

      <div ref={datePickerEnd}></div>
    </>
  );
}
