import React from 'react'

export default function Link(props) {
    return (
        <>
            <a href={props.Url}
                target={ props.Target ? props.Target : "_self" }
                className={`${props.Icon ? "link--with-icon" : ""} ${props.ExtraClass ? props.ExtraClass : ""} ${props.Type !== 'button' ? "link" : "button"}`}
                onClick={props.onClick}>

                {props.Icon === 'download' &&
                    <div className="link__icon">
                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.18201 4.09094V12.2728" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12.2728 8.18176L8.18192 12.2727L4.09101 8.18176" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <rect x="0.00012207" y="14.7273" width="0.818183" height="2.45455" fill="black"/>
                            <rect x="15.5455" y="14.7273" width="0.818182" height="2.45455" fill="black"/>
                            <rect y="17.1816" width="16.3636" height="0.818182" fill="black"/>
                        </svg>
                    </div>
                }

                {props.Type !== 'button' &&
                    <div className="link__label">
                        {props.children}
                        {props.Label}
                    </div>
                }

                {props.Type === 'button' &&
                    props.Label
                }
            </a>

            {
                props.AdditionalText &&
                props.Type === "button" &&
                    <div className="button__text">{props.AdditionalText}</div>
			}
        </>
    )
}
